.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.form-control {
  padding: 3px 5px 3px 5px !important;
  border-radius: 0 !important;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;

}

.custom-select select {
  display: none;

  /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


html, body {
  height: 100vh;
  width: 100vw;
  top: 0 !important;

  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 13px !important;

}

h1 {
  font-size: 15.5px !important;
  margin: 0;
}

h2 {
  font-size: 14.5px !important;
  margin: 0;
}

h3 {
  font-size: 13.5px !important;
  margin: 0;
}

body {
  display: flex;
}

.app-container {

  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
}


.home-container {

  width: 100%;
  flex-grow: 1;

  max-height: 100%;
  display: flex;
  overflow-y: hidden;

}

.non-home-container {

  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;

  display: flex;
  flex-direction: column
}

.home-left-bar {
  flex: 1;
  padding: 5px 5px 5px 5px;
  min-width: 200px;
  background-color: #eee;


  overflow-y: auto;
  display: flexbox;


}

.home-content {
  flex: 10;
  padding: 5px;
  max-width: 100% !important;
  max-height: 100% !important;
  overflow-y: auto;

}

img {
  max-width: 100%;
  object-fit: contain;
  
}

iframe {
  max-width: 100% !important;
  object-fit: contain;

}

.single-article {
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow-y: auto;
}

.gridItem {
  display: flexbox;

  font-size: 14px;
  padding: 5px 10px 20px 10px;
  border-bottom: 1px solid #ccc;
  background-color: #ffffff;

}

.gridItem.small {
  line-height: 25px;
  padding: 5px 10px 5px 10px;

}

.gridItem:hover {
  background-color: rgb(225, 225, 225);
  cursor: pointer;

}

.gridItem.selected {
  background-color: #61dafb;
}

.gridItem.selected:hover {
  background-color: #7c87fe;
}

.gridItemMainRow {
  font-size: 14px;
}

.gridItemSubRow {
  font-size: 10px;
  float: right;
}



::placeholder {
  opacity: 0.6 !important
}

.grid {

  border: 1px solid #ccc;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px;
  background-color: #d6d6d6;


}


.accordion-button {
  padding-block: 10px !important;
  padding-left: 20px !important;
  padding-right: 8px !important;
  font-size: 14px !important;
}

.accordion-button::after {
  background-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
}

.btn-link {
  font-size: 13px !important;
}

.btn-default {
  background-color: rgb(213, 213, 213) !important;
}

.btn-default:hover {
  background-color: rgb(232, 232, 232) !important;
}

.rescom-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}





.rescom-left-panel {
  min-width: 250px;
  max-width: 250px;
  background-color: #d6d6d6;
  box-shadow: 0 0px 8px rgba(146, 144, 144, 0.904);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

@media (max-width: 768px) {
  .rescom-left-panel {
    margin-left: -230px;
    padding-right: 100px;
    z-index: 1;
    transition: .15s .4s;




  }

  .rescom-left-panel:hover {
    margin-left: -0px;
    padding-right: 0px;
    z-index: 1;

  }

  .rescom-main-panel {
    margin-left: 0px;

  }



}



.rescom-left-panel-top {
  padding-top: 6px;
  flex: 100;
  overflow-y: auto;
  
}

.rescom-left-panel-bottom {

  overflow-y: auto;
  box-shadow: 0 0 15px #555a;
  border-top: 1px solid #5555;

  

}

.rescom-left-panel-item {
  padding-inline: 10px;
  padding-block: 5px;
  background-color: #eee;
  border-bottom: 1px solid #bbb;
  cursor: pointer;
  color: #333;
  font-size: 11.5px;
  user-select: none;
}

.rescom-left-panel-item.selected {
  background-color: rgb(209, 205, 255);
}

.rescom-left-panel-item:hover {
  color: black;
  background-color: rgb(221, 221, 221);
}

.rescom-left-panel-item.selected:hover {
  color: black;
  background-color: rgb(168, 160, 250);
}


/* width */
.rescom-left-panel ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.rescom-left-panel ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.rescom-left-panel ::-webkit-scrollbar-thumb {
  background: rgb(186, 186, 186);
}

/* Handle on hover */
.rescom-left-panel ::-webkit-scrollbar-thumb:hover {
  background: rgb(164, 164, 164);
}

.rescom-body-panel {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 100%;
  overflow-y: hidden;
}

.rescom-top-panel {
  display: flex;

  justify-content: end;
  flex-shrink: "1";
  background-color: #e5e5e5;
  box-shadow: 0 0px 10px #555;
  border-top: 1px solid #ccc;
  padding: 2px 5px;
  /* min-height: 25px; */
  z-index: 2;
}

.rescom-bottom-bar {

  display: flex;
  justify-content: space-between;
  font-size: 11px;
  color: #555;
  font-weight: 600;
  flex-shrink: "1";
  background-color: #e5e5e5;
  box-shadow: 0 3px 10px #555;
  border-top: 1px solid #ccc;
  padding: 0px 0px 1px 4px;
  align-items: baseline;
  user-select: none;
}

.rescom-bottom-bar-icon {
  margin: 0px 4px 0px 2px;
  font-size: 13px;
  opacity: .5;
}

.rescom-bottom-bar-icon:hover {
  cursor: pointer;
  opacity: 1;

}

.rescom-bottom-bar-button {

  padding: 0px 5px 0px 5px;
  margin: 0px 10px 0px 10px;
  font-size: 11px;
  font-weight: 500;
}

.rescom-bottom-bar-button:hover {
  cursor: pointer;
  opacity: 1;
  color: black;

}


.login-box {
  position: absolute;
  margin: 0 !important;
  padding: 50px;

  height: fit-content;
  background: rgba(255, 150, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.login-bg-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  filter: blur(1.5px);
  -webkit-filter: blur(1.5px);
  border: 10px solid black;
  overflow: hidden;

}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  height: 100vh !important;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  background-color: #eee;
  box-shadow: 1px 0px 10px #00000077;
  padding-left: 10px;
  margin-bottom: 5px;
  padding-right: 0px;
}

.nav-item {
  text-decoration: none;
  font-size: 13.5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0px 15px 0px 5px;
  color: #555;

}

.nav-item.logo {
  font-size: 20px;
}

.login-logo {
  width: 100%;
  font-size: 35px;
  text-shadow: 0px 0px 70px #001affcc
}

.accordion-body {
  padding: 5px 20px 5px 20px !important;
}

input:focus {
  box-shadow: 0 0 0 #4412db43 !important;
}

.login-input:focus {
  box-shadow: -2px -2px 1px #4412db43 !important;
  background-color: #e0edffb9 !important;
}

.login-input {
  background-color: #ffffff93 !important;
  box-shadow: -2px -2px 0.5px #12014643;
  border: 0px !important;

}

.search-bar {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white !important;
  border-radius: 10px;
  padding-right: 5px;
  padding-left: 10px;
  border: 1px solid #bbb;
  margin-inline: 10px;
  margin-bottom: 5px;
}

.search-placeholder {
  padding-right: 10px;
  color: #aaa;
  font-size: 12px;

}

.search-icon {
  color: #888;
  font-size: 13px;
}

.order-list {
  margin: 0 0 20px 0;
  border: 1px solid #ccc;
  user-select: none;
}

.order-list.left-bar {
  margin: 0 0 2px 0;

  user-select: none;
}


.order-list-header {
  padding: 4px;
  background-color: #eee;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;

}

.order-list-header:hover {
  background-color: #ddd;
  color: black;
}

.order-list-header-icon {
  opacity: .4;
  margin: 4px;
}

.order-list-header-icon:hover {
  opacity: 1;

}

.order-list-item {
  padding: 4px;
  border-top: 1px solid #ccc;
  display: flex;
  user-select: text;
  background-color: white;
  overflow-x: hidden;
  display: flex;
  white-space: nowrap;
}

.order-list-item:hover {
  word-break: break-all;
  white-space: normal;
}


.order-list-items-text {
  flex-grow: 1;
  text-overflow: ellipsis;

  overflow: hidden;
  font-size: 12px;
  user-select: none;


}

.order-list-items-icons {
  flex-shrink: 1;
}

.order-list-item-btn {
  font-size: 13.2px;
  margin-right: 0px;
  margin-left: 5px;
  margin-top: 2px;
  color: #aaa;
  cursor: pointer;
  min-width: 20px;
}

.order-list-item-btn:hover {
  color: black;
}

.order-list-item-btn.disabled, .order-list-item-btn.disabled:hover {
  color: #eee;
  cursor: default;
}

.modal {
  font-family: Verdana;
  font-size: 11px;
  color: black;
  font-weight: 500;
}

.modal-header, .modal-footer {
  padding: 10px 12px 10px 12px !important;
}

.modal-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  margin: -15px;
  display: flex;
  flex-direction: column
}

.btn {
  padding: 2px 15px !important;
  font-size: 14px !important;
}

.btn:focus {
  box-shadow: none !important;
}

.rescom-main-panel {
  flex-grow: 1;
  overflow-x: hidden;
  margin-left: 0px;
  z-index: 0;
}

.rescom-article-title {
  font-size: 14px;
  font-weight: 500;

  margin-bottom: 15px;
  background-color: #e5e5e5;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.rescom-article {
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.history-title {
  background-color: #eee;
  padding: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  position: sticky;
  top: 0
}

.history-item {
  margin: 1px 0px;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  background-color: rgb(253, 252, 243);
  box-shadow: 0 0 10px #5555;
}

.history-item.type-5 .historyTypeName {
  color: rgb(157, 0, 0);
}

.history-item.type-4 .historyTypeName {
  color: rgb(0, 26, 255);
}

.history-item-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.history-item-data {
  padding-inline: 10px;
  font-size: 11px;
}

.history-item-data.userFullName {

  text-align: end;
  font-size: 10px;
  white-space: nowrap;

}

.history-item-row {
  justify-content: space-between;
}

.history-item-data.date {
  white-space: nowrap;
}

.search {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #5559;
  background-blend-mode: color-burn;
  z-index: 10;
  display: flex;
  justify-content: center;

  backdrop-filter: blur(2px);

}

.search-box {

  margin-top: 8vh;
  width: min(600px, 90vw);
  height: min(500px, 80vh);
  background-color: white;
  z-index: 10;
  box-shadow: 0 0 20px #5555;
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.search-box-input {
  background-color: rgba(171, 171, 171, 0.133);
  border: 1px solid #5555;
  box-shadow: 0 0 1px #5555;
  border-radius: 5px;
  padding: 2px 10px;
  width: 100%;
  height: 25px;
}

.search-box-input:focus {
  outline: 0px;
}

.search-box-spinner {
  opacity: 0.3;
  min-height: 20px;
  width: 100%;

  justify-content: center;
  padding-top: 10px;
  display: flex;
}





.search-grid {

  overflow-y: auto;
  overflow-x: hidden;


}


.search-grid-item {
  display: flexbox;

  font-size: 14px;
  padding: 5px;
  margin: 2px;

}


.search-grid-item:hover {
  background-color: rgb(244, 244, 244);
  cursor: pointer;
  transition: .5s;

}

.search-grid-item {
  padding: auto;
}

.search-grid-item .main {
  font-size: 12px;
  color: darkblue;
  cursor: pointer;
}

.search-grid-item .sub {
  font-size: 10px;
  text-align: end;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 15px;
}

.search-grid-item .icons {
  display: flex;
  flex-direction: row;
  color: #5558;
  font-size: 12px;
  padding-right: 10px;
  display: none;
  
}

.search-grid-item .icon {
  padding-right: 5px;
}

.search-grid-item:hover .icons {
  display: contents;
  transition: 5s;

}


#scrollToTopButton
{
  display: unset;
  position: fixed;
  z-index: 99;
   opacity:.6;
  bottom: 35px;
  right: 35px;
  
  font-size: 10px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px; 
}
#scrollToTopButton:hover {
  background-color: #555;
  opacity:.8;
}